/* CookieConsentModal.css */

/* Modal background overlay */
.modal-overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(107, 114, 128, 0.75);
    transition-opacity: 300ms ease-out;
}

/* Dialog box wrapper */
.modal-wrapper {
    position: fixed;
    inset: 0;
    z-index: 10;
    width: 100vw;
    overflow-y: auto;
    display: flex;
    align-items: end;
    justify-content: center;
    padding: 1rem;
}

/* Dialog panel */
.dialog-panel {
    position: relative;
    transform: translate(0, 0);
    overflow: hidden;
    border-radius: 0.75rem;
    background-color: #ffffff;
    text-align: left;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 300ms ease-out;
}

/* Header of the modal */
.dialog-header {
    background-color: #ffffff;
    padding: 1.25rem 1rem 1rem;
}

/* Title of the modal */
.dialog-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: #3b82f6;
    /* Primary color */
}

/* Content text */
.dialog-content {
    margin-top: 0.75rem;
    max-height: 24rem;
    overflow-y: scroll;
}

.dialog-content p {
    font-size: 0.875rem;
    color: #6b7280;
    /* Text color */
}

/* Link style */
.dialog-link {
    font-size: 0.875rem;
    color: #3b82f6;
    /* Primary color */
    text-decoration: underline;
}

/* Checkbox section */
.checkbox-section {
    margin-top: 1.25rem;
    color: #3b82f6;
    /* Text color */
}

/* Label for checkboxes */
.checkbox-label {
    font-weight: 500;
}

/* Disabled button state */
.button-disabled {
    background-color: #e5e7eb;
    cursor: not-allowed;
}

/* Primary button */
.button-primary {
    background-color: #3b82f6;
    hover: bg-blue-600;
    color: white;
}

.button-primary:hover {
    background-color: #2563eb;
}

/* Cancel button */
.button-cancel {
    background-color: white;
    border: 1px solid #ddd;
    color: #3b82f6;
    /* Primary color */
}

.button-cancel:hover {
    background-color: #f1f5f9;
}

/* Container for buttons */
.button-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.button-container.md-row {
    flex-direction: row;
}

.cookieConsentModal {
    width: 100%;
    /* O imposta una larghezza specifica */
    max-width: 600px;
    /* Limita la larghezza massima */
    height: auto;
    /* Imposta altezza automatica */
    margin: 0 auto;
    /* Centra la modale orizzontalmente */
    position: fixed;
    /* La posizione della modale è fissa */
    top: 50%;
    /* Posiziona la modale al centro della pagina */
    left: 50%;
    transform: translate(-50%, -50%);
    /* Centra verticalmente e orizzontalmente */
    z-index: 9999;
    /* Assicurati che la modale sia in primo piano */
}