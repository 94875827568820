.footer-container {
    display: flex;
    /* Flexbox attivato */
    justify-content: space-between;
    /* Spazia le colonne */
    align-items: flex-start;
    /* Allinea i contenuti in alto */
    flex-wrap: wrap;
    /* Se lo spazio è piccolo, le colonne vanno a capo */
}

.footer-contacts,
.footer-informative {
    width: 45%;
    /* Ogni sezione occupa circa metà spazio */
}

.footer-contacts h5,
.footer-informative h5 {
    margin-bottom: 10px;
    font-size: 16px;
}

.footer-informative a {
    color: #ffffff;
    /* Cambia il colore del link */
    text-decoration: none;
    /* Rimuove il sottolineato */
}

.footer-informative a:hover {
    text-decoration: underline;
    /* Effetto hover */
}

.footer-bottom {
    margin-top: 20px;
    font-size: 14px;
    color: #ffffff;
    text-align: center;
}