@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap'); 
:root {
  /* Colors */
  --trypan-blue-2: #00617f;
  --flickr-pink: #99ce58;
  --byzantine: #b5179eff;
  --light-pink: #ffc6ff;
  --text-color: #efefef;
  --dark-text-color: rgb(44, 44, 44);
  --delete-color: #df372b;
  --divider-color: rgba(0, 0, 0, 0.1);
  --dark-bg: #222;
  --soft-dark: #272724;
  /*Scala di Grigi*/
  --extra-light-gray: #f8f9faff;
  --soft-light-gray: #f2f2f3;
  --light-gray: #ced4daff;
  --primary-gray: #adb5bdff;
  --soft-dark-gray: #89949e;
  --dark-gray: #6c757dff;
  --extra-dark-gray: #343a40ff;
  /* Global */
  --letter-spacing: 0.08rem;
  --transition: all 0.5s ease;
  --btn-transition: all 0.125s ease;
  --radius: 0.25rem;
  --img-radius: 0.25rem 0.25rem 0rem 0rem;
  --card-radius: 0rem 0rem 0.25rem 0.25rem;
  --btn-radius: 16px;
  --light-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  --dark-shadow: 4px 5px 15px rgba(5, 6, 6, 0.4);
  --azzurro-logo: #95C8E8;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: white;
  color: var(--dark-text-color);
  font-family: 'Poppins', sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* GLOBAL STYLE */
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
  color: unset;
}

hr {
  border: none;
  border-bottom: 1px solid var(--divider-color);
}

h1,
h2,
h3,
h4 {
  letter-spacing: var(--letter-spacing);
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  font-size: 0.9rem;
  letter-spacing: var(--letter-spacing);
}
@media screen and (min-width: 753px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 2rem;
  }
  h4 {
    font-size: 1.25rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}

.btn {
  display: inline-block;
  border: none;
  padding: 0.4rem 1.25rem;
  border-radius: var(--btn-radius);
  box-shadow: var(--light-shadow);
  letter-spacing: var(--letter-spacing);
  font-size: 0.55rem;
  transition: var(--btn-transition);
  cursor: pointer;
  font-weight: 700;
  text-transform: uppercase;
}

.icon-btn {
  padding: 0.4rem 0.2rem;
  background-color: transparent;
  box-shadow: unset;
  justify-self: center;
}

.icon,
.nav-icon {
  padding: 0;
  margin: 0;
  transition: var(--btn-transition);
}

.nav-icon {
  color: #99ce58;
}

.icon {
  font-size: 1rem;
  color: var(--flickr-pink);
}

.underline,
.topline {
  position: absolute;
  bottom: -13px;
  height: 2px;
  width: 80%;
  background-color: var(--flickr-pink);
  left: 50%;
  transform: translateX(-50%);
  border-radius: var(--radius);
}

.topline {
  top: -13px;
}

/*
====================
NAV STYLE
====================
*/

.nav {
  font-family: 'Poppins', sans-serif!important;
  background-color: transparent;
  box-shadow: var(--light-shadow);
  display: flex;
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  align-items: center;
}

.nav-header {
  display: flex;
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  -moz-justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
}

.nav-brand {
  margin-bottom: 0.1rem;
  color: var(--trypan-blue-2);
  text-transform: lowercase;
  font-variant: small-caps;
}

.nav-toggler {
  font-size: 1.5rem;
  padding-bottom: 0;
  align-self: center;
  transition: var(--btn-transition);
}

.nav-toggler:hover .nav-icon {
  transform: rotateZ(90deg);
}

.nav-links {
  display: none;
}
.nav-links > .nav-item {
  margin-right: 40px;
}
.nav-link {
  
  display: grid;
  display: -moz-grid;
  display: -ms-grid;
  display: -webkit-grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  gap: 1rem;
  white-space: nowrap;
}

.nav-text {
  font-size: 1rem;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
}
.containerHome{
  margin-left: 8%;
  margin-right: 8%;
  transition: cubic-bezier(0.6, 0.04, 0.98, 0.335);
}
@media screen and (max-width: 990px) {
  .containerHome{
    margin: 4%!important;;
    
  } 
  .areaDescription{
    line-height: 1.5!important;
  }
}
@media screen and (max-width: 700px) {
  .containerHome{
    margin: 0!important;;
    
  } 
}
.logo-img{
  height: 100px;
  width: 300px;
  padding: 20px;
  padding-left: 0;
  background-origin: content-box!important;
}
.container-shapes{
  display: inline-block;
  position: relative;
  width: 100%;
  padding-bottom: 10%;
  vertical-align: middle;
  overflow: hidden;
}
.svg-shapes{
  position: absolute;
  top: 40%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
}
.svg-shapes-rotated{
  position: absolute;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  top: 7%;
  margin: auto;
  transform: rotate(180deg);
  text-align: center;
}
.imageSlider{
  height: 650px;
  width:100%
}
.circle-slide{
  border-radius: 50%;
  height: 20px;
  width: 20px;
}

.sectorIconDiv{
  border-radius: 50%;
  height: 100%;
  width: 100%;
  margin: auto;
  font-size: 60px;
  color:var(--azzurro-logo);
  transition: var(--btn-transition);
}
.sectorIcon{
  position: relative;
  top: calc(50% - 32px);
}
.sectorIcon:hover{
  font-size: 70px;
  position: relative;
  top: calc(50% - 37px);
  transition: all 0.525s ease;

}
.sectorsDiv{

  width: 75%;
  margin: auto;
  text-align: center;
  margin-bottom: 80px;
  margin-top: 50px;
}
.sectorDiv {
  width: 44%;
  display: inline-block;
}
.awssld__bullets {
  bottom: 40px!important;
  z-index: 19;
}
.sidebar-social > .nav-item {
  font-size: 25px;
}
.sectorTextWrap{
  padding: 0 10px;
  text-align: left!important;
}
.sliderTitleBottom{
  margin: 0px 20px 90px 0px!important;
}
.sliderProdotti > .awssld__bullets > [data-index="0"]{
  background-image: url("../public/image/icon/icona_open.jpg");
  background-size: contain;
  height: 40px;
  width: 40px;
}
.sliderProdotti > .awssld__bullets > [data-index="1"]{
  background-image: url("../public/image/icon/icona_software.jpg");
  background-size: contain;
  height: 40px;
  width: 40px;
}
.sliderProdotti > .awssld__bullets > [data-index="2"]{
  background-image: url("../public/image/icon/icona_domotica.jpg");
  background-size: contain;
  height: 40px;
  width: 40px;
}
.sliderProdotti > .awssld__bullets > [data-index="3"]{
  background-image: url("../public/image/icon/icona_digital.jpg");
  background-size: contain;
  height: 40px;
  width: 40px;
}
.sliderProdotti > .awssld__bullets > [data-index="4"]{
  background-image: url("../public/image/icon/icona_ricerca.jpg");
  background-size: contain;
  height: 40px;
  width: 40px;
}
.containerAreaData{
  margin-bottom: 50px;
  padding:0px 40px;
}
.containerApp{
  margin-bottom: 20vh;
}
@media screen and (min-width: 992px) {
  .ourProjectButton{
    font-size: 35px;
  }
}
.floatLeft{
  float: left;
  padding-left: 50px;
}
.floatRight{
  padding-right: 50px;
}
@media screen and (max-width: 600px) {
  .dreamocWrap{
    margin-bottom: 50px!important;
  }
.floatLeft{
  float: none!important;
}
.floatRight{
  padding-right: 0px!important;
}
.dreamocDiv{
  width: 100%!important;
  display: block!important;
  padding: 30px!important;
}
.imageDreamoc{
  width: 100%!important;
  display: block!important;
}
.sfera1{
  left:65%!important;
 }
 .sfera2{
  left:73%!important;
 }
 .sfera3{
  left: 73%!important;;
 }
 .sfera4{
  left:65%!important;
 }
 .serviceFont{
   font-size: 3vw!important;
 }
 @media screen and (max-width: 700px) {
}
.service1{
  left: 72%!important;
  top: 37%!important;
}
.service2{
  left: 73%!important;
  top: 54%!important;
}
.service3{
  left: 71%!important;
  top: 67.5%!important;
}
.service4{
  left: 66%!important;
  top: 25%!important;
}
.service5{
  left: 71%!important;
  top: 37%!important;
}
.service6{
  left: 71%!important;
  top: 47.5%!important;
}
.service7{
  left: 66%!important;
  top: 66%!important;
}
.service8{
  left: 70%!important;
  top: 25.5%!important;
}
.service9{
  left: 74%!important;
  top: 53%!important;
}
.service10{
  left: 69%!important;
  top: 69%!important;
}
.service11{
  left: 65%!important;
  top: 27.5%!important;
}
.service12{
  left: 69%!important;
  top: 50%!important;
}
.service13{
  left: 65%!important;
  top: 66%!important;
}
}
.aa-Panel{
  left:unset!important;
  width: 10%!important;
}
@media screen and (max-width: 600px) {
  .sectorDiv {
    width: 100%!important;
    height: auto!important;
  }
  .sectorIconDiv{
    width: 100%!important;
    height: 100%!important;
  }
  .sectorTitle{
    font-size: 5vw!important;
  }
}
@media screen and (max-width: 800px) and (min-width: 600px) {
  .sectorDiv {
    width: 100%!important;
    height: auto!important;
  }
  .sectorIconDiv{
    width: 60%!important;
    height: 60%!important;
  }
  .sectorTitle{
    font-size: 3vw!important;
  }
}
@media screen and (max-width: 992px) {
  .flag{
    height: 30px!important;
    width: 30px!important;
   
  }
  .flag1{
    height: 30px!important;
    width: 30px!important;
  }
  .awssld__bullets {
    bottom: 10px!important;
   }
   .sliderProdotti > .awssld__bullets > button {
    height: 30px!important;
    width: 30px!important;
   }
  .awssld__bullets > [data-index="0"]{
   height: 20px!important;
   width: 20px!important;
  }
  .awssld__bullets > [data-index="1"]{
    height: 20px!important;
    width: 20px!important;
   }
   
   .awssld__bullets > [data-index="2"]{
    height: 20px!important;
    width: 20px!important;
   }
   .awssld__bullets > [data-index="3"]{
    height: 20px!important;
    width: 20px!important;
   }
   .awssld__bullets > button{
    height: 20px!important;
    width: 20px!important;
   }
   .awssld__bullets > [data-index="4"]{
    height: 20px!important;
    width: 20px!important;
   }
   .aboutHeaderImage{
     height: 70vh!important;
   }
  

  .qualityDiv{
    width: 100%!important;
    height: auto!important;
  }
  .MondayIcon{
    display: none!important;
  }
  .sectorsDiv {
    margin-left:0 ;
    margin-right: 0;
    width: 100%;
    display: block!important;
  }
  .sectorTextWrap{
    padding-left: 40px;
  }
  .qualityRow {
    display: block!important;
  }
}
.qualityIcon{
  height: 120px;
  margin-bottom: 10px;
}
.sectorTitle{
  margin: 10px 0;
  font-size: 1.7vw;
}
.aa-ItemDescription{
  text-transform: lowercase!important;

}
.aa-List{
  padding-top: 20px!important;
}
.sectorDiv {
  vertical-align: text-top;
  height: 250px;
  display: inline-block;
  text-align: center;
  padding:30px 0;
  position: relative;
}
.qualityDiv{
  width: 50%;
  height: 50%;
  display: inline-block;
  text-align: center;
  padding:30px 10px;
}
.sectorTextWrap{
  width: 70%;
  display: inline-block;
  text-align: center;

}
.iconWrap{
  width: 25%;
  display: inline-block;
  text-align: center;
  height: 100%;
  vertical-align: middle;

}
.imageWhyChoose{
  width: 100%;
  height: 50vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
#imgOurProduct{
  height: 50vh;
}
.layerImageWhyChoose{
    background-color: rgba(34, 34, 34, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

}
.titleWhyChoose{
  font-size:55px;
  top: calc(50% - 30px);
  text-align: center;
  margin: auto;
  color:white;
  position: relative;
  text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);

}
.btnOurProductHome{

  font-size: 3vw;
  top: calc(50% - 30px);
  text-align: center;
  margin: auto;
  color: white;
  border-radius: 3px;
  padding: 20px 10px;
  background-color: #95C8E8;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  font-weight: bold;
  box-shadow: 0px 4px 12px rgb(0 0 0 / 25%);
  transition: var(--btn-transition);
}

.btnOurProductHome:hover{
  transform: scale(1.1);
}
.iconSector{
  transition: var(--btn-transition);
}
.iconSector:hover{
  transform: scale(1.1);
}
.footer-social .socialTop .mondayIcon{
  display: none;
}
.vertical_center{
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
#chiamaci{
  margin-bottom: 50px;
}

.whereTitle{
  text-align: center;
  margin: 40px!important;
  margin-top: 180px!important;
  font-size: 50px;
  margin: auto;
}
.socialTop {
  display: none;
}

.nav-item {
  transition: var(--btn-transition);
}

.nav-item:hover {
  color: var(--flickr-pink);
}
.shapeOne{
  opacity: 0.6;
}
.shapeTwo{
  opacity: 0.6;
}
.container {
  width: 100%;
  margin: 0 auto;
}

/*
====================
NAV STYLE MEDIA QUERY
====================
*/
.awssld__bullets button{
  margin: 0px !important;
  background-color: white!important;
}
.awssld__controls__arrow-left:after{
  background-color: white!important;
}
.awssld__controls__arrow-right:after{
  background-color: white!important;
}
.awssld__controls__arrow-left:before{
  background-color: white!important;
}
.awssld__controls__arrow-right:before{
  background-color: white!important;
}
@media screen and (max-width: 991px) {
  .aa-DetachedSearchButton {
    background-color: var(--color-background);
    border: none;
    border-bottom: 1px solid #94c11f;
    margin-bottom: 10px;
    border-radius: 0!important;
  }
}
@media screen and (max-width: 991px) {
  #search{
    display: none;
  }
}
@media screen and (min-width: 992px) {
  .nav {
    -moz-justify-content: space-around;
    -webkit-justify-content: space-around;
    -ms-justify-content: space-around;
    justify-content: space-around;
    height: 5rem;
  }

  .next {
    right: 0;
    transform: translateX(150%);
    opacity: 0;
  }
  
  .prev {
    left: 0;
    transform: translateX(-150%);
    opacity: 0;
  }
  
  .active {
    transform: translate(-50%, 0%);
    opacity: 1;
    position: relative;
  }
  .slide{
    width: 100%;
    left: 50%;
    transition: var(--transition);
    position: absolute;
  }
  .slider {
    display: flex;
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    flex-wrap: nowrap;
    position: relative;
    min-height: 400px;
    width: 100%;
  }
  .slider-btn-group {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
  }
  
  .btn-group .btn-slider {
    background-color: transparent;
    height: -moz-fit-content;
    height: fit-content;
    color: white;
    font-size: 50px;
    box-shadow: none;
  }
  .btn-slider:hover{
    background-color: black;
    opacity: 0.7;
  }
  .nav-container {
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    -moz-justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    width: 78%!important;
  }

  .nav-header {
    text-align: left;
    width: fit-content;
  }

  .links-container {
    justify-self: center;
  }
  .nav-toggler {
    display: none;
  }

  .nav-links {
    width: 50%;
    display: flex;
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    -moz-justify-content: end;
    -webkit-justify-content: end;
    -ms-justify-content: end;
    justify-content: end;
  }

  .nav-icon {
    font-size: 1.4em;
  }
  .social-icon {
    font-size: 50px;
    color:white;
  }
  .socialTop {
    display: grid;
    display: -moz-grid;
    display: -ms-grid;
    display: -webkit-grid;
    align-items: center;
    grid-template-columns: auto auto auto;
    gap: 1rem;
    font-size: 1.2rem;
    margin-right: 10px;
  }
}

/*
====================
END NAV STYLE
====================
*/

.btn-group {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  width: 100%;
}

.btn-primary {
  background-color: var(--flickr-pink);
  color: white;
  font-size: 0.8rem;
  text-transform: uppercase;
  padding: 0.9rem 1.8rem;
  width: fit-content;
}

.btn-primary:hover {
  background-color: var(--byzantine);
  transform: scale(1.02);
}
.aa-Form{
  border:none!important;
  
}
.aa-SubmitIcon{
  color: rgb(151, 191, 13)!important;
}
.aa-Input{
  transition: all 0.125s ease;
  display: none!important;
  border-bottom: 1px solid rgb(151, 191, 13)!important;
}
.divTextPortfolio > a {
  vertical-align: top;
}
.divTextPortfolio > ul > li {
  color: #101010;
}
/*
====================
SIDEBAR STYLE
====================
*/
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  width: 100%;
  background: white;
  transform: translateX(-100%);
  transition: var(--transition);
}

.sidebar-content {
  height: 50%;
  display: grid;
  display: -moz-grid;
  display: -ms-grid;
  display: -webkit-grid;
  gap: 2rem;
}

.sidebar .nav-header {
  margin: 0;
  height: 4rem;
  margin: auto;
}

.sidebar .nav-header .nav-icon {
  margin: 0;
  padding: 0;
  font-size: 1.8rem;
}

.sidebar-links {
  display: grid;
  display: -moz-grid;
  display: -ms-grid;
  display: -webkit-grid;
  display: grid;
  place-items: flex-start;
  gap: 1rem;
  font-size: 1.2em;
}

.sidebar-links .nav-item {
  width: 100%;
  padding: 0.5rem 0rem;
}

.sidebar-social {
  width: fit-content;
  margin: 2rem auto;
  font-size: 1.5rem;
  display: -moz-inline-flex;
  display: -ms-inline-flex;
  display: -webkit-inline-flex;
  display: inline-flex;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
}
.social-icon{
  text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
}
.activeLink{
  color: var(--flickr-pink) !important;
}
.nav-item:hover .nav-link .nav-icon {
  transform: scale(1.1);
  color: var(--flickr-pink) !important;
}
.nav-item:hover .nav-link .social-icon {
  transform: scale(1.1);
  color: var(--flickr-pink) !important;
  text-shadow: 0px 14px 10px rgba(0, 0, 0, 0.4);
}
.nav-item:hover .nav-link #facebook {
  transform: scale(1.1);
  color: #4267B2 !important;
}
.nav-item:hover .nav-link #twitter {
  transform: scale(1.1);
  color:#1DA1F2!important;
}
.nav-item:hover .nav-link #linkedin {
  transform: scale(1.1);
  color: #0e76a8 !important;
}

.show-sidebar {
  transform: translateX(0%) !important;
}

/*
====================
DRAGGABLE AREA
====================
*/
.dragContainer{
  width: 100%;
  height: 90vh;
  background-color: #2222;
  position: relative;
  text-align: center;
}

.openInnovation{
  border-radius: 50%;
  background-color: #99ce58;
  height: 200px;
  width: 200px;
  position: absolute;
  cursor: move;
  margin: auto;
  user-select: none;
  z-index: 10;
  
}
.aa-SourceFooter{
  display: none!important;
}
#circle1{
  border-radius: 50%;
  background-color: #004A67;
  width: 200px;
  height: 200px;
  cursor:pointer;
  position: absolute;
  left:50px;
  top:50px;
  transition: var(--btn-transition);
  z-index: 4;
}

#circle2{
  border-radius: 50%;
  background-color: #95C8E8;
  height: 200px;
  width: 200px;
  cursor:pointer;
  position: absolute;
  left:50px;
  bottom:50px;
  transition: var(--btn-transition);
  z-index: 4;
}
#circle3{
  border-radius: 50%;
  background-color: #B59852;
  height: 200px;
  width: 200px;
  cursor:pointer;
  position: absolute;
  right:50px;
  top:50px;
  transition: var(--btn-transition);
  z-index: 4;
}
#circle4{
  border-radius: 50%;
  background-color: #646464;
  height: 200px;
  width: 200px;
  cursor:pointer;
  position: absolute;
  right:50px;
  bottom:50px;
  transition: var(--btn-transition);
  z-index: 4;
}

@media screen and (max-width: 700px) {
  .openInnovation{
    height: 100px;
    width: 100px;
  }
  .dragContainer{
    height: 60vh;
  }
  #circle1{
    width: 100px;
    height: 100px;
  }
  #circle2{
    width: 100px;
    height: 100px;
  }
  #circle3{
    width: 100px;
    height: 100px;
  }
  #circle4{
    width: 100px;
    height: 100px;
  }
}

.aboutHeaderImage{
  width: 100%;
  height: 70vh;
  background-size: cover;
}
.aboutSubTitle{
  margin-top:20px;
  font-size: 20px!important;
}
.aboutCardContainer{
  display: block;
}

.aboutDiv{
  display: inline-block;
  width: 33%;
  height: 400px;
  text-align: justify;
  text-justify: inter-word;
  padding:20px 20px;
  margin:20px 0;

}
@media screen and (max-width: 1000px) {
  .aboutDiv{
    display: inline-block;
    width: 50%;
    padding:20px 40px;
  }
  .aboutCardContainer{
    margin: 0px 20px;
  }
}
@media screen and (max-width: 700px) {
  .aboutDiv{
    display: inline-block;
    width: 100%;
    padding:20px 40px;
  }
}

.aboutImageContainer{
  position: relative;
  display: block;
  height: 300px;
  box-shadow: 0px 4px 12px rgb(0 0 0 / 25%);
  border-radius: 10px 10px 0 0;
}

.profileImage{
  position: absolute;
  top: 0;
  height: 300px;
  width: 100%;
  background-position: center!important;
  background-repeat: no-repeat!important;
  background-size: cover!important;
  border-radius: 10px 10px 0 0;
  -webkit-transition: background-image 0.2s ease-in-out;
  transition: background-image 0.2s ease-in-out;

}
.profileRole{
  margin:10px 0;
  font-size: 13px;
  color:#222!important;
  text-align: left;
  font-weight: bold;
  padding-left:15px;
}
.profileName{
  background-color: #004e6a;
  color: white;
  padding: 15px;
  text-align: left;
  font-size: 1.6vw;
  border-radius: 0 0 10px 10px;
}
.profileDescription{
  color: #222;
  font-size: 14px;
}

.portfolioButtonDiv{
  width: 75%;
  padding:5px;
  margin:auto;
  margin-top:50px;
  margin-bottom: 80px;
  overflow-x: auto;
}

/*
// PORTFOLIO BUTTON CSS
*/
/* sviluppo_software */

/*
.ActivePortfolio_sviluppo_software {
  background-color: rgb(181,152,82, 0.5)!important;
  color: rgb(181,152,82)!important;
  font-weight: bold;
  text-transform: uppercase;
}

.portfolioButton_sviluppo_software:hover{
  background-color: rgb(181,152,82, 0.5);
  color: rgb(181,152,82)!important;
  font-weight: bold;
}
*/
.ActivePortfolio_sviluppo_software {
  border-bottom:3px solid rgb(181,152,82)!important;
  text-transform: uppercase;
  color: rgb(181,152,82)!important;
}

.portfolioButton_sviluppo_software:hover{
  border-bottom:3px solid rgb(181,152,82)!important;
  color: rgb(181,152,82)!important;
}
.portfolioButton_sviluppo_software {
  display: inline-block;
  width: 24%;
  margin-right: 1%;
  border:none;
  background-color: #fff;
  cursor: pointer;
  padding: 10px 0px;
  transition: var(--btn-transition);
}
#description_portfolio_portfolio_sviluppo_software b{
  color:rgb(181,152,82);
}
#description_portfolio_portfolio_sviluppo_software a{
  color:rgb(181,152,82);
}
/* domotica */
.ActivePortfolio_domotica {
  border-bottom:3px solid rgb(149, 200, 232)!important;
  color: rgb(149, 200, 232)!important;
  text-transform: uppercase;
}
.portfolioButton_domotica:hover{
  border-bottom:3px solid rgb(149, 200, 232);
  color: rgb(149, 200, 232)!important;

}

.portfolioButton_domotica {
  display: inline-block;
  width: 24%;
  margin-right: 1%;
  border:none;
  background-color: #fff;
  cursor: pointer;
  padding: 10px 0px;
  transition: var(--btn-transition);
}
#description_portfolio_portfolio_domotica b{
  color:rgb(149, 200, 232);
}
#description_portfolio_portfolio_domotica a{
  color:rgb(149, 200, 232);
}
/* digital_signage */
.ActivePortfolio_digital_signage {
  border-bottom:3px solid rgb(0, 74, 103)!important;
  color: rgb(0, 74, 103)!important;
  text-transform: uppercase;
}
.portfolioButton_digital_signage:hover{
  border-bottom:3px solid rgb(0, 74, 103);
  color: rgb(0, 74, 103)!important;
}

.portfolioButton_digital_signage {
  display: inline-block;
  width: 24%;
  margin-right: 1%;
  border:none;
  background-color: #fff;

  cursor: pointer;
  padding: 10px 0px;
  transition: var(--btn-transition);
}
#description_portfolio_portfolio_digital_signage b{
  color:rgb(0, 74, 103);
}
#description_portfolio_portfolio_digital_signage a{
  color:rgb(0, 74, 103);
}
/* ricerca_e_sviluppo */
.ActivePortfolio_ricerca_e_sviluppo {
  border-bottom:3px solid rgb(151, 191, 13)!important;
  color: rgb(151, 191, 13)!important;
  text-transform: uppercase;
}
.portfolioButton_ricerca_e_sviluppo:hover{
  border-bottom:3px solid rgb(151, 191, 13);
  color: rgb(151, 191, 13)!important;

}

.portfolioButton_ricerca_e_sviluppo {
  display: inline-block;
  width: 24%;
  margin-right: 1%;
  border:none;
  background-color: #fff;
  cursor: pointer;
  padding: 10px 0px;
  transition: var(--btn-transition);
}
#description_portfolio_portfolio_ricerca_e_sviluppo b{
  color:rgb(151, 191, 13);
}
#description_portfolio_portfolio_ricerca_e_sviluppo a{
  color:rgb(151, 191, 13);
}
/*
//
*/

.portfolioButton{
  display: inline-block;
  width: 22%;
  margin:10px 20px;
  border:none;
  background-color: #fff;

  font-size: 30px;
  cursor: pointer;
  border-radius: 10px;
  padding: 10px 30px;
  transition: var(--btn-transition);
}
.portfolioButton:hover{
  background-color: rgb(181,152,82, 0.5);
}
.ActivePortfolio{
  background-color: rgb(181,152,82, 0.5);
}
.image_description{
  transform: translate3d(0, 0, 0)!important;
  padding: 15px;

  opacity: 1;
  transition: transform 0.45s cubic-bezier(0.15, 0.3, 0.15, 1), opacity 0.35s ease-out;
  color: #fff;
  height: 100%;
  text-shadow: 2px 2px 4px rgb(255 255 255 / 70%);
}
.titleClient{
  text-align: center;
  margin: auto;
  margin-top: 70px !important;
  color:#101010;
  margin: 40px 0;
  font-size: 5vh;
}
.divClientWrap{
  width: 75%;
  margin: auto;
  position:relative;
  margin-bottom: 150px;
}
  .divClient {
    width: 100%;
    height: 100%;
    text-align:center;

   }

.clientLogo{
  display: inline-block;
  height: 150px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-origin: content-box;
  flex-shrink: 0;
  padding: 10px;
}
.domotica_logo{
  width: 25%;
}
.sviluppo_software_logo{
  width: 33%;
}
.digital_signage_logo{
  width: 25%;
}
.ricerca_e_sviluppo_logo{
  width: 20%;
}
.containerDivDescriptionProduct{
  padding:20px 0px;
}
.containerDivDescriptionPortflio{
  padding-top: 100px;
  width: 75%;
  margin: auto;
}
.MarkerMap{
  width: 50px;
  height: 50px;
  margin: -50px -50px;
}
li{
  color: var(--primary-gray);
  font-size: 0.9rem;
  letter-spacing: var(--letter-spacing);
}
.hideArea{
  display: none;
}
.DivDescriptionPortflio{
 /* border-left: 2px solid #004a67;*/
  width: 100%;
  padding:0 10px;

}
.titleDescriptionDiv{
  text-transform: uppercase;
}
.rowDescriptionPortfolio{
  width: 100%;
  display: block;
  margin-bottom: 15px;
}
.divIndexPortfolio{
  width: 30%;
  vertical-align: top;
  color: #101010;
  font-weight: bold;
  display: inline-block;
}
.divTextPortfolio{
  width: 70%;
  vertical-align: top;
  color: #101010;
  display: inline-block;
}
@media screen and (max-width: 500px) {
  .divTextPortfolio{
    width: 100%;
  }
  .divIndexPortfolio{
    width: 100%;
  }
}
.DivDescriptionPortflio > #description_portfolio_portfolio_sviluppo_software > h3{
  text-transform: uppercase;
  color:rgb(181,152,82);
 
 }
 .DivDescriptionPortflio > #description_portfolio_portfolio_sviluppo_software > h4{
  color:rgb(181,152,82);
 
 }
 .DivDescriptionPortflio > #description_portfolio_portfolio_sviluppo_software > hr{
  border-bottom: 3px solid rgb(181,152,82);
  margin-top: 10px!important;;
  margin-bottom: 30px;
 
 }
 .DivDescriptionPortflio > #description_portfolio_portfolio_domotica > h3{
  text-transform: uppercase;
  color:rgb(149, 200, 232)!important;
 
 }
 .DivDescriptionPortflio > #description_portfolio_portfolio_domotica > .rowDescriptionPortfolio> .divIndexPortfolio> h4{
  color:#6B6B6B!important;
 
 }
 .spaceClassBottom{
   margin-bottom: 30px;
 }
 .DivDescriptionPortflio > #description_portfolio_portfolio_domotica > .rowDescriptionPortfolio> .divIndexPortfolio> h5{
  color:rgb(149, 200, 232)!important;
  width: 100vw;
 
 }
 .DivDescriptionPortflio > #description_portfolio_portfolio_domotica > hr{
  border-bottom: 3px solid rgb(149, 200, 232)!important;
  margin-top: 10px!important;;
  margin-bottom: 30px;
 
 }
 .DivDescriptionPortflio > #description_portfolio_portfolio_digital_signage > h3{
  color:rgb(0, 74, 103)!important;
  text-transform: uppercase;
 
 }
 .DivDescriptionPortflio > #description_portfolio_portfolio_digital_signage > .rowDescriptionPortfolio> .divIndexPortfolio>h4{
  color:#6B6B6B!important;
 
 }
 .DivDescriptionPortflio > #description_portfolio_portfolio_digital_signage > .rowDescriptionPortfolio> .divIndexPortfolio>h5{
  color:rgb(0, 74, 103)!important;
  width: 100vw;
 
 }
 .DivDescriptionPortflio > #description_portfolio_portfolio_digital_signage > hr{
  border-bottom: 3px solid rgb(0, 74, 103)!important;
  margin-top: 10px!important;;
  margin-bottom: 30px;
 
 }
 .DivDescriptionPortflio > #description_portfolio_portfolio_ricerca_e_sviluppo > h3{
  text-transform: uppercase;
  color:rgb(151, 191, 13)!important;
 
 }
 .DivDescriptionPortflio > #description_portfolio_portfolio_ricerca_e_sviluppo > h4{
  color:rgb(151, 191, 13)!important;
 
 }
 .DivDescriptionPortflio > #description_portfolio_portfolio_ricerca_e_sviluppo > hr{
  border-bottom: 3px solid rgb(151, 191, 13)!important;
  margin-top: 10px!important;;
  margin-bottom: 30px;
 
 }
.paragDescriptionPortflio{
  color:black!important;
  text-align: justify;
  font-size: 18px!important;
}
.scrollableDivOtherProject{
  max-height:50vh;
  overflow-y: scroll;
}
#sviluppo_software > div > .sliderHome > .awssld__bullets > .awssld__bullets--active {
  background-color: rgb(181,152,82)!important;
  border:2px solid white!important;
}
#domotica > div > .sliderHome > .awssld__bullets > .awssld__bullets--active {
  background-color:rgb(149, 200, 232)!important;
  border:2px solid white!important;
}
#digital_signage > div > .sliderHome > .awssld__bullets > .awssld__bullets--active {
  background-color:rgb(0, 74, 103)!important;
  border:2px solid white!important;
}
#ricerca_e_sviluppo > div > .sliderHome > .awssld__bullets > .awssld__bullets--active {
  background-color:rgb(151, 191, 13)!important;
  border:2px solid white!important;
}
#sviluppo_software > div > .sliderHome > .awssld__bullets > button {
  border:2px solid rgb(181,152,82)!important;
}
#domotica > div > .sliderHome > .awssld__bullets >  button{
  border:2px solid rgb(149, 200, 232)!important;
  margin-left: 15px!important;
}
#digital_signage > div > .sliderHome > .awssld__bullets > button{
  border:2px solid rgb(0, 74, 103)!important;
}
#ricerca_e_sviluppo > div > .sliderHome > .awssld__bullets > button {
  border:2px solid rgb(151, 191, 13)!important;
}
#domotica > div > .sliderHome > .awssld__bullets > [data-index="9"]{
  background-color: rgb(149, 200, 232)!important;
  border: 2px solid white!important;
}
#digital_signage > div > .sliderHome > .awssld__bullets > [data-index="4"]{
  background-color: rgb(0, 74, 103)!important;
  border: 2px solid white!important;
}
.paragDescriptionPortflio > .rowDescriptionPortfolio > .divTextPortfolio > a{
  font-size: .9rem!important;
}
.awssld__bullets > button{
  border:2px solid #004e6a!important;
  height: 13px;
  width: 13px;
  margin-left: 20px!important;
}
.awssld__bullets > .awssld__bullets--active{
  background-color: #004e6a!important;
  border:2px solid white!important;

}
.sliderProdotti > .awssld__bullets > button {
  margin-right: 10px;
}
/*
====================
SIDEBAR MEDIA QUERY STYLE
====================
*/

@media screen and (min-width: 992px) {
  .sidebar {
    display: none;
  }
  
}
/*
====================
END SIDEBAR STYLE
====================
*/

/*
====================
HERO STYLE
====================
*/

.hero-img-container {
  position: relative;
  background-position: top;
  background-size: contain;
  min-height: 96vh;
  width: 100%;
  z-index: 1;
}

.hero-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
}

.hero-container {
  height: 100%;
}

/*=== HOME HERO ===*/

.home-hero {
  display: -moz-grid;
  display: -ms-grid;
  display: -webkit-grid;
  display: grid;
  height: 100%;
  align-items: center;
}

.home-hero-text {
  width: 100%;
  display: -moz-grid;
  display: -ms-grid;
  display: -webkit-grid;
  display: grid;
  place-items: center;
  text-align: center;
  gap: 2rem;
  color: var(--text-color);
}

.home-hero-title {
  display: -moz-grid;
  display: -ms-grid;
  display: -webkit-grid;
  display: grid;
  gap: 1rem;
}

.home-hero-title h2 {
  font-weight: 900;
}

.home-hero-title h4 {
  font-variant: small-caps;
}

.home-hero-text p {
  align-self: center;
  color: var(--light-gray);
  max-width: 70ch;
  line-height: 1.6;
}

.home-hero-text .btn {
  align-self: center;
  border-radius: 22px;
}

/*=== HOME HERO MEDIA ===*/
@media screen and (min-width: 992px) {
  .home-hero {
    grid-template-columns: repeat(12, 1fr);
  }

  .home-hero-text {
    grid-column: 1/7;
    gap: 3rem;
    text-align: left;
    place-items: start;
  }

  .home-hero-text p {
    align-self: flex-start;
    color: var(--light-gray);
    max-width: 70ch;
    line-height: 1.6;
  }

  .home-hero-text .btn {
    align-self: unset;
  }

  .home-hero-img {
    grid-column: 8/-1;
  }
}
/*=== ABOUT HERO ===*/

.about-hero {
  display: -moz-grid;
  display: -ms-grid;
  display: -webkit-grid;
  display: grid;
  height: 100%;
  place-items: center;
}
.about-text {
  position: relative;
  text-align: center;
  line-height: 1.5;
  max-width: 45ch;
  color: var(--light-gray);
}
.about-text h3 {
  line-height: 1.4;
  font-style: italic;
}
@media screen and (min-width: 992px) {
  .about-text {
    max-width: 65ch;
  }
}

/*=== CONTACT HERO ===*/
.contact-hero {
  display: -moz-grid;
  display: -ms-grid;
  display: -webkit-grid;
  display: grid;
  height: 100%;
  align-items: center;
}

.contact-hero-text {
  width: 100%;
  display: -moz-grid;
  display: -ms-grid;
  display: -webkit-grid;
  display: grid;
  place-items: center;
  text-align: center;
  gap: 2rem;
}

.contact-hero-title {
  margin-top: 3rem;
  display: -moz-grid;
  display: -ms-grid;
  display: -webkit-grid;
  display: grid;
  gap: 1rem;
}

.contact-title {
  line-height: 1.05;
}

.contact-subtitle {
  color: var(--primary-gray);
  line-height: 1.4;
  font-weight: 500;
  max-width: 55ch;
  margin: auto;
}

.contact-form-container {
  margin-top: 2rem;
  display: -moz-grid;
  display: -ms-grid;
  display: -webkit-grid;
  display: grid;
  background-color: white;
  border-radius: var(--radius);
  padding-top: 1.5rem;
  padding-bottom: 2rem;
  box-shadow: var(--light-shadow);
}

.contact-form {
  display: -moz-grid;
  display: -ms-grid;
  display: -webkit-grid;
  display: grid;
  gap: 1rem;
}

.form-group {
  display: -moz-grid;
  display: -ms-grid;
  display: -webkit-grid;
  display: grid;
  gap: 0.4rem;
}

.form-group label {
  text-transform: lowercase;
  font-variant: small-caps;
  font-weight: 700;
  font-size: 1.2em;
  color: var(--trypan-blue-2);
}

.form-group .input {
  border-radius: 0;
  border: 0px 0px 1px 0px solid var(--dark-gray);
  padding-left: 0.1rem;
  letter-spacing: var(--letter-spacing);
}

.contact-form button {
  justify-self: center;
  align-self: center;
  border-radius: 22px;
  box-shadow: var(--light-shadow);
}

@media screen and (min-width: 992px) {
  .contact-form-container {
    margin-top: 4rem;
  }
  .contact-hero-text {
    margin-top: 4rem;
  }
}

/*
====================
END HERO STYLE
====================
*/

/*
====================
HOMESCREEN STYLE
====================
*/

.home-screen {
  margin: 2rem auto;
}

.search-bar {
  width: 100%;
}

.form-container {
  display: -moz-grid;
  display: -ms-grid;
  display: -webkit-grid;
  display: grid;
  -moz-justify-content: flex-start;
  -webkit-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
}

.form-container form {
  display: -moz-grid;
  display: -ms-grid;
  display: -webkit-grid;
  display: grid;
  gap: 1rem;
}

form label .drink {
  -ms-grid-column-align: start;
  justify-self: flex-start;
}

.form-container form .input-search {
  display: -moz-grid;
  display: -ms-grid;
  display: -webkit-grid;
  display: grid;
  grid-template-columns: auto auto;
  gap: 3rem;
  align-items: center;
}

.input {
  padding: 0.5rem 1rem;
  border-radius: 16px;
  border: 1px solid var(--primary-gray);
  color: var(--dark-gray);
}

.input:focus {
  box-shadow: 0px 0px 1px 0px rgb(44, 44, 44);
  outline: 0px transparent;
}

.search-bar .result {
  align-self: left;
  margin-top: 1rem;
}

@media screen and (min-width: 992px) {
  .home-screen {
    margin: 3rem auto;
  }

  .search-bar {
    width: 100%;
    display: -moz-grid;
    display: -ms-grid;
    display: -webkit-grid;
    display: grid;
    grid-template-columns: auto auto;
    -moz-justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
  }

  .form-container form {
    grid-template-columns: auto auto auto;
    align-items: center;
    gap: 3rem;
  }

  .search-bar .result {
    align-self: left;
  }
}

/*
====================
END HOMESCREEN STYLE
====================
*/

/*
====================
ABOUTSCREEN STYLE
====================
*/

.about-value {
  margin: 0 auto;
  background-color: var(--extra-light-gray);
}
.about-value-content {
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: -moz-grid;
  display: -ms-grid;
  display: -webkit-grid;
  display: grid;
  gap: 2rem;
}
.about-value h3.brand-secondary-color {
  text-align: center;
}
.card-section {
  display: -moz-grid;
  display: -ms-grid;
  display: -webkit-grid;
  display: grid;
  place-items: center;
  gap: 2rem;
}

.about-team {
  margin: 0 auto;
  background-color: white;
}

.about-team .team-img {
  position: relative;
  height: 70vh;
}

.about-team .team-img::before {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100.1%;
  background-color: white;
  height: 15vh;
  clip-path: polygon(100% 40%, -40% 110%, 100% 100%);
}

.about-team-content {
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: -moz-grid;
  display: -ms-grid;
  display: -webkit-grid;
  display: grid;
  place-items: center;
  gap: 2rem;
}

@media screen and (min-width: 753px) {
  .about-team .team-img::before {
    clip-path: polygon(100% 10%, -20% 100%, 100% 100%);
  }
}

@media screen and (min-width: 992px) {
  .about-value {
    margin: 0rem auto;
  }

  .about-team .team-img::before {
    clip-path: polygon(100% 0, -50% 130%, 100% 100%);
  }

  .about-value-content {
    padding-top: 3rem;
    padding-bottom: 5rem;
    gap: 3rem;
  }
  .card-section {
    grid-template-columns: 1fr 1fr 1fr;
    align-items: unset;
  }
}

@media screen and (min-width: 1280px) {
  .about-team-content .card-section {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 980px) {
  .containerDivDescriptionPortflio{
    padding: 50px 20px;
    width: 100%;
  }
  .portfolioButton_sviluppo_software{
    font-size: 4vw!important;
  }
  .portfolioButton_domotica{
    font-size: 4vw!important;
  }
  .portfolioButton_digital_signage{
    font-size: 4vw!important;
  }
  .portfolioButton_ricerca_e_sviluppo{
    font-size: 4vw!important;
  }
  .portfolioButtonDiv{
    margin-top:50px;
    width: 100%;
    margin-bottom: 0;
  }
  .portBtn{
    width: 50%;
    margin:auto;
    font-weight: bold;
    font-size: 2.5vw!important;
    padding: 10px 5px;
    
    text-transform: uppercase;
  }
  .divClientWrap{
    height: 100%;
    margin-bottom: 150px!important;
  }
  .clientLogo{

    width: 70%;
    height: 100px;
    margin: auto;
    display: block;
  }
  .titleClient{
    margin-bottom: 0;
    font-size: 3vh;
    margin-top: 0!important;
    
  }
  .awssld {
    height: 300px!important;
  }
  .profileName{
    font-size: 4vw;
  }
}
.sliderHome > .awssld__bullets{
  bottom: 20px!important;
}
.sliderSubproduct > .awssld__bullets{
  bottom: 20px!important;
}
/*
====================
END ABOUTSCREEN STYLE
====================
*/
.portBtn{
  color:#101010;
  font-size: 1.5vw;
  text-transform: uppercase;
  font-weight: bold;
}
/*
====================
FOOTER STYLE
====================
*/
.footer {
  background-color: #222222;
  padding: 30px;
  position: relative;
}

.footer-container {
  width: 50%;
  height: 100%;
  color: var(--text-color);
  display: inline-block;

}
.footer-social{
  position: absolute;
  right: 0;
  bottom: 0;
  margin-bottom: 30px;
  display: inline-block;
}
.mondayIcon{
  font-size: 25px;
}
.mondayIcon:hover{
  transform: scale(1.1);
  color: var(--flickr-pink)
}

.inputForm{
  width:100%;
  background-color: #f2f1f0;
  border: none;
  border-radius: 3px;
  height:40px;
}
.textAreaForm{
  width:100%;
  background-color: #f2f1f0;
  border: none;
  border-radius: 3px;
  height: 200px;
  margin-bottom: 70px!important;
}
.formButton{
  width:210px;
  height: 60px;
  background-color: #B59852;
  color:white;
  border:none;
  font-size:30px;
  border-radius: 3px;
  font-weight: bold;

}
.elseP{
  text-align: center;
  font-size: 20px;
  margin: 40px 0;
}
.callButton{
  border: none;
  border-radius: 3px;
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  height: 60px;
  width: 210px;
  background-color: #004E6A;
  margin-bottom: 50px;
  appearance: auto!important;
}
.serviceButton{
  padding: 10px 20px;
  background-color: #004E6A;
  color: white;
  border: none;
  font-size: 2vw;
  border-radius: 3px;
  font-weight: bold;
  text-shadow: none!important;
  position: absolute;
  margin-top: 20px;
}
.serviceSviluppo:hover{
  color:rgb(181,152,82)!important;
  transform: scale(1.05);
}
.serviceDomotica:hover{
  color:rgb(149, 200, 232)!important;
  transform: scale(1.05);
}
.serviceDigital:hover{
  color:rgb(0, 74, 103)!important;
  transform: scale(1.05);
}
.serviceRicerca:hover{
  color:rgb(151, 191, 13)!important;
  transform: scale(1.05);
}
.ourProjectButton{
  width: 400px;
  padding: 10px 20px;
  height: 80px;
  background-color: #004E6A;
  color: white;
  border: none;
  border-radius: 3px;
  font-weight: bold;

  margin-top: 50px;
}

.buttonRow{
  text-align: center;
  margin:auto;
}
.buttonRowProduct{
  text-align: center;
  margin:auto;
  margin-top: 50px!important;
  margin-bottom: 150px!important;
}
.contactForm{
  width: 75%;
  margin:auto;
  margin-bottom: 150px;
}
.inlineFormField{
  width: 50%;
  display: inline-block;
  padding:10px;
}
.formField{
  padding:10px;
}
.formField > p {
  margin-bottom: 10px;
}
.inlineFormField > p {
  margin-bottom: 10px;
}
.inlineFormRow{
  display: flex;
}
.qualityRow{
  width: 75%;
  margin: auto!important;
  display: flex;
  margin-top: 70px;
  margin-bottom: 70px;

}
.container{
  width: 90%;
}
.brand-color {
  color: var(--flickr-pink);
}

.brand-secondary-color {
  color: var(--trypan-blue-2);
}

.footer-text {
  font-size: 1.03em !important;
}
.image__description {
  transform: translate3d(0, 0, 0)!important;
  padding-top: 15px;
  font-family: 'Sen', sans-serif;
  opacity: 1;
  width: 50%;
  margin-left: 10%;
  float: right;
  transition: transform 0.45s cubic-bezier(0.15, 0.3, 0.15, 1), opacity 0.35s ease-out;
  color: #fff;
  height: 100%;
  text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);

}
.awssld .sliderProdotti > .awssld__wrapper > .awssld__container > .awssld__box > .awssld__content > img {
  object-fit: contain;
}
.awssld__content{
  display: block!important;
}
.sliderTitleTop{
  font-size: 2.5vw;
  margin-top: 5%;
}
.sliderTitleBottom{
  position: absolute;
  font-size: 2.0vw;
  bottom: 0;
  left: 0;
  width: 85%;
  margin: 0px 140px 140px 0px;
}
.leftFloat{
  float: left!important;
}
.sliderHome{
  height: 70vh;

}
.redirectDiv{
  position: relative;
}


#overDivVG{
  width: 100%;
  height: 99%;
  opacity: 0.5;
  position: absolute;
  top:0;
}
#overDivVG:hover{
  background-color: #222;
}
#overDivVG:hover > #redirectArrowVG{
  display: block;
}
#redirectArrowVG{
  position: absolute;
  color: white;
  font-size: 60px;
  top: 50%;
  left: 50%;
  display: none;
}

#overDivSH{
  width: 100%;
  height: 99%;
  opacity: 0.5;
  position: absolute;
  top:0;
}
#overDivSH:hover{
  background-color: #222;
}
#overDivSH:hover > #redirectArrowSH{
  display: block;
}
#redirectArrowSH{
  position: absolute;
  color: white;
  font-size: 60px;
  top: 50%;
  left: 50%;
  display: none;
}
.sliderSubproduct{
  height: 50vh;
  margin-bottom: 100px;
}
.sliderProdotti{
  height: 100%;
  background-size: contain!important;
  height: 70vh;
}
.serviceFont{
  font-size: 1.7vw;
  font-weight: bold;
  color: white;
  transition: var(--btn-transition);
}
.svc {
  height: 5%;
  position: absolute;
  width: 30%;
  transition: var(--btn-transition);
  cursor: pointer;
 
}
.service1{
  left: 63%;
  top: 33%;
}
.service2{
  left: 64%;
  top: 57%;
}
.service3{
  left: 63%;
  top: 72.5%;
}
.service4{
  left: 60%;
  top: 20%;
}
.service5{
  left: 63%;
  top: 34%;
}
.service6{
  left: 63.5%;
  top: 50%;
}
.service7{
  left: 60%;
  top: 70%;
}
.service8{
  left: 63%;
  top: 23%;
}
.service9{
  left: 64.5%;
  top: 54%;
}
.service10{
  left: 62%;
  top: 74%;
}
.service11{
  left: 59%;
  top: 21.5%;
}
.service12{
  left: 62%;
  top: 50%;
}
.service13{
  left: 59.5%;
  top: 71%;
}
.qualityTitle{
  margin-bottom: 30px!important;
}
.sfera1{
  background-color: red;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 20%;
  position: absolute;
  width: 20%;
  left: 54%;
  top: 7.5%;
  cursor: pointer;
  animation-name: stretch;
  animation-duration: 1.0s;
  animation-timing-function: ease-out;
  animation-direction: alternate;
  animation-iteration-count:infinite;
  animation-play-state: running;
}
@keyframes stretchText {
  0% {
    transform: scale(.95);
  }

  100% {
    transform: scale(1.0);
  }
}
@keyframes stretch {
  0% {
    transform: scale(.9);
  }

  100% {
    transform: scale(1.0);
  }
}
.sfera2{
  background-color: blue;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 20%;
  position: absolute;
  width: 20%;
  left: 58%;
  top: 27%;
  cursor: pointer;
  animation-name: stretch;
  animation-duration: 1.0s;
  animation-timing-function: ease-out;
  animation-direction: alternate;
  animation-iteration-count:infinite;
  animation-play-state: running;
}
.sfera3{
  background-color: yellow;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 20%;
  position: absolute;
  width: 20%;
  left: 58%;
  top: 53%;
  cursor: pointer;
  animation-name: stretch;
  animation-duration: 1.0s;
  animation-timing-function: ease-out;
  animation-direction: alternate;
  animation-iteration-count:infinite;
  animation-play-state: running;
}
.sfera4{
  background-color: green;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 20%;
  position: absolute;
  width: 20%;
  left: 54%;
  top: 70%;
  cursor: pointer;
  animation-name: stretch;
  animation-duration: 1.0s;
  animation-timing-function: ease-out;
  animation-direction: alternate;
  animation-iteration-count:infinite;
  animation-play-state: running;
}
.areaTitle{

  border-left: 4px solid #b59852;
  font-size: 40px;
  padding-left: 20px;
  margin-bottom: 20px;
}
.areaService{

  
  margin-bottom: 20px;
}
.appDiv{
  width: 80%;
  margin: auto;
}
.dreamocDiv{
  width: 50%;
  display: inline-block;
  text-align: justify;
  vertical-align: top;
  margin: auto;
}
.hrSeparator{
  margin: 50px 0;
  border-bottom: none;
}

.imageApp{
  width: 100%;
}
.home > .awssld__wrapper> .awssld__container > .awssld__box > .awssld__content > img, .awssld__content > video {

  opacity: 0.6;
}
.imageDreamoc{
  width: 50%;
  display: inline-block;
  height: 40vh;
  background-size: contain!important;
  background-repeat: no-repeat!important;
  background-position: center!important;
}
.dreamocWrap{
  margin-bottom: 150px;
  position: relative;
}
.dreamocTitle{
  border-left: 4px solid #004e6a;
  font-size: 30px;
  padding-left: 20px;
  margin-bottom: 20px;
}
.dreamocDescription{
  margin-bottom: 60px;
}
.appTitle{

  font-size: 25px;
  margin-bottom: 20px;
  margin-top: 30px;
}
.flagContainer{
  display: block;
  margin-bottom:50px;
  width: 80%;
  margin: auto;
}
.flag{
  height: 60px;
  width: 60px;
  display: inline-block;
  background-size:contain!important;
  margin-left:20px;
}
.flag1{
  height: 60px;
  width: 60px;
  display: inline-block;
  background-size:contain!important;
}
.appDescription{

  color: #222;
  margin-bottom: 20px;
}
.areaDescription{
  line-height: 2;
  color: #222;
  margin-bottom: 20px;
  text-align: justify;
  text-justify: inter-word;
}
.portfolioLink{
  margin-top: 150px;
  text-align: center;
  width: 100%;
  font-size: 3vw;
  margin-bottom: 50px;
  padding-left: 30px;
  padding-right: 30px;
}
.ourAppTitle{
  margin-top: 150px;
  text-align: center;
  width: 100%;
  font-size: 40px;
  margin-bottom: 50px;
  padding-left: 30px;
  padding-right: 30px;
}
.fullHeight{
}
/*
====================
END FOOTER STYLE
====================
*/

/* SPINNER STYLE AND ANIMATION*/
.loading-position {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: var(--trypan-blue-2);
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.page-content p,
.page-content h2,
.page-content h3{
  padding: 20px 0;
}

.page-content a {
  text-decoration: underline;
  font-weight: bold;
}

.page-content ul li {
  color: #000;
}